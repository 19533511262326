define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model", ["exports", "@glimmer/component", "@ember/object/computed", "discourse/helpers/category-link", "discourse/helpers/discourse-tag", "@ember/component", "@ember/template-factory"], function (_exports, _component, _computed, _categoryLink, _discourseTag, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _ActivityPubActorModel;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubActorModel = _exports.default = (_dec = (0, _computed.equal)("args.actor.model_type", "category"), _dec2 = (0, _computed.equal)("args.actor.model_type", "tag"), (_class = (_ActivityPubActorModel = class ActivityPubActorModel extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isCategory", _descriptor, this);
      _initializerDefineProperty(this, "isTag", _descriptor2, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-actor-model">
        {{#if this.isCategory}}
          {{categoryLink @actor.model}}
        {{/if}}
        {{#if this.isTag}}
          {{discourseTag @actor.model.name}}
        {{/if}}
      </div>
    
  */
  {
    "id": "ZAXLVDf2",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-model\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCategory\"]],[[[1,\"        \"],[1,[28,[32,0],[[30,1,[\"model\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isTag\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,1,[\"model\",\"name\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model.js",
    "scope": () => [_categoryLink.default, _discourseTag.default],
    "isStrictMode": true
  }), _ActivityPubActorModel), _ActivityPubActorModel), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCategory", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isTag", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});